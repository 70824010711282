import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { SectionHeading } from 'components/misc/Headings.js';

import WebsiteDevelopment from '../../images/website.png';
import AppDevelopment from '../../images/mobile.png';
import CustomSoftwareSolutions from '../../images/solutions.png';
import ManualAutomationTesting from '../../images/testing.png';
import CloudComputing from '../../images/cloud.png';
import TechnicalContentWriting from '../../images/content-writing.png';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 text-justify font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: WebsiteDevelopment,
      title: 'Website Development',
      description:
        'We develop responsive, intuitive websites that stand out. From custom web solutions to seamless CMS integrations and powerful e-commerce platforms, we elevate your online presence with style and functionality.'
    },
    {
      imageSrc: AppDevelopment,
      title: 'App Development',
      description:
        "We immerse ourselves in crafting apps that captivate and empower users. With sleek interfaces and seamless functionalities, we customize each app to meet the unique needs of your audience. Whether it's intuitive navigation or cutting-edge features, we're committed to transforming your vision into a reality that fits perfectly in the palm of your users' hands."
    },
    {
      imageSrc: CustomSoftwareSolutions,
      title: 'Custom Software Solutions',
      description:
        'We provide tailored solutions meticulously designed to meet the specific needs and workflows of your organization or user base. With a focus on addressing unique challenges and optimizing efficiency, we craft bespoke software from scratch, ensuring seamless integration with your existing systems and future scalability.'
    },
    {
      imageSrc: ManualAutomationTesting,
      title: 'Automation Testing',
      description:
        'Maximize software reliability and performance with our advanced QA testing and automation services.. We examine every aspect of your software, identifying and resolving potential issues before they impact your users. We ensure thorough coverage and rapid feedback, empowering you to deliver high-quality software with confidence and efficiency.'
    },
    {
      imageSrc: CloudComputing,
      title: 'Cloud Computing',
      description:
        "Our team of experts harnesses the power of leading cloud platforms to optimize performance, scalability, and cost-efficiency for your business. Whether you're looking to migrate existing workloads or build new cloud-native applications, we're here to help you leverage the flexibility and innovation of the cloud to drive your digital transformation forward."
    },
    {
      imageSrc: TechnicalContentWriting,
      title: 'Technical Content Writing',
      description:
        'We specialize in crafting clear, concise, and engaging content tailored to your specific audience and industry. From technical documentation and user guides to blog posts and whitepapers, we ensure that your message resonates with readers while effectively conveying complex information. We combines technical expertise with creative flair to deliver content that educates, informs, and inspires action.'
    }
  ];

  const descriptionsToDisplay = cards.map(
    (card) => {
      return card.description;
      const cutIndex = card.description.indexOf(' ', 95);
      return card.description.slice(0, cutIndex) + ' ...';
    }
  );

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw="text-primary-500">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{descriptionsToDisplay[i]}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
