import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Form, Formik } from 'formik';
import { initialValues, formSchema, handleSubmit } from './utils';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const Container = tw.div`relative self-stretch`;

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 ` : tw`md:ml-12 lg:ml-16`
]);

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:pl-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-8`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const SubmitButton = tw.button`w-full sm:w-32 mt-12 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const CustomForm = tw(Form)`mt-4 md:mt-10 text-sm flex flex-col`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const DateInput = styled(DatePicker)`
  ${tw`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
`;

const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-40`}
`;

const ErrorText = tw.p`text-red-700 mt-2`;

const getForm = () => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={formSchema}>
    {(props) => {
      const { setFieldValue, values, errors, isValid, isSubmitting } = props;

      return (
        <CustomForm>
          <TwoColumn>
            <TextColumn textOnLeft>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Your Email Address"
                onChange={(e) => setFieldValue('email', e.target.value)}
                value={values.email}
              />
              {errors.email && <ErrorText>{errors.email}</ErrorText>}
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Full Name"
                onChange={(e) => setFieldValue('name', e.target.value)}
                value={values.name}
              />
              {errors.name && <ErrorText>{errors.name}</ErrorText>}
              <DateInput
                id="datetime"
                name="datetime"
                type="datetime-local"
                placeholder="Date and Time"
                showTimeSelect
                dateFormat={'Pp'}
                onChange={(date) => setFieldValue('datetime', date)}
                selected={values.datetime}
                style={{ color: '#A0AEBF' }}
              />
              {errors.datetime && <ErrorText>{errors.datetime}</ErrorText>}
              <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
                Submit
              </SubmitButton>
            </TextColumn>
            <TextColumn>
              <Textarea
                id="message"
                name="message"
                placeholder="Your Message Here"
                onChange={(e) => setFieldValue('message', e.target.value)}
                value={values.message}
              />
              {errors.message && <ErrorText>{errors.message}</ErrorText>}
            </TextColumn>
          </TwoColumn>
        </CustomForm>
      );
    }}
  </Formik>
);

export default () => {
  return (
    <Container>
      <FormContainer>
        <div tw="mx-auto max-w-6xl" style={{ marginLeft: '12px' }}>
          <h2>Unlock Perfection! How Can We Assist You Today?</h2>
          {getForm()}
        </div>
      </FormContainer>
    </Container>
  );
};
