import React from 'react';

import AnimationRevealPage from 'helpers/AnimationRevealPage.js';

import Hero from 'components/hero/BackgroundAsImage.js';

import Features from 'components/features/DashedBorderSixFeatures';
import MainFeature from 'components/features/TwoColSingleFeatureWithStats2.js';
import SliderCard from 'components/cards/ThreeColSlider.js';
import Testimonial from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview.js';

import FAQ from 'components/faqs/SimpleWithSideImage.js';

import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import SubscribeNewsLetterForm from 'components/forms/SimpleSubscribeNewsletter.js';

import Footer from 'components/footers/MiniCenteredFooter.js';
import faqImage from 'images/faq.jpeg';

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <MainFeature />
      <Features />
      <SliderCard />
      <Testimonial
        subheading="Testimonials"
        heading={
          <>
            Our Clients <span tw="text-primary-500">Love Us.</span>
          </>
        }
        description="Discover what our valued customers have to say about the exceptional expertise and support they've received. Their words speak volumes about the dedication and effectiveness of our team in bringing success to their businesses."
        testimonials={[
          {
            imageSrc:
              'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
            quote:
              "Hi there, I'm Barbara, founder of InnovateStart. Partnering with Techmanion was enlightening; their youthful energy and commitment to deadlines were impressive. Using cutting-edge project management tools, I could monitor our project's progress in real-time. The outstanding results reflect their dedication and skill. I rate them 9 out of 10 and highly recommend collaboration with Techmanion.",
            customerName: 'Barbara McIntosh',
            customerTitle: 'Founder, InnovateStart'
          },
          {
            imageSrc:
              'https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
            quote:
              "Hello everyone, I'm Danielle, Managing Director at Creative Horizons. Collaborating with Techmanion's energetic team was outstanding. Their commitment to deadlines and consistent updates impressed me. The exceptional results reflect their dedication and expertise. I would totally recommend them to anyone looking for a reliable tech partner.",
            customerName: 'Danielle C.',
            customerTitle: 'Managing Director, Creative Horizons'
          },
          {
            imageSrc:
              'https://i.postimg.cc/0y0LtDs5/business-people-discussing-issues-cafe-table.jpg',
            quote:
              "Hey, I'm Charles, leading operations at NextGen Solutions. Partnering with Techmanion was a game-changer. Their professionalism in project execution, punctuality, and transparent communication was impressive. Our project's success under their care was remarkable. I rate them 10/10 and highly recommend collaborating with them.",
            customerName: 'Charles Jones',
            customerTitle: 'Lead Operations, NextGen Solutions'
          }
        ]}
        textOnLeft={true}
      />
      <FAQ
        imageSrc={faqImage}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions ?</span>
          </>
        }
      />
      <ContactUsForm />
      <SubscribeNewsLetterForm />
      <Footer />
    </AnimationRevealPage>
  );
};
