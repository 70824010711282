import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import EmailIllustrationSrc from 'images/email.jpeg';
import axios from 'axios';
import { industryList } from './utils';
import { Form, Formik } from 'formik';
import { initialValues, formSchema, handleSubmit } from './utils';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const CustomForm = tw(Form)`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Select = tw.select`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const ErrorText = tw.p`text-red-700 mt-2`;

export default ({
  subheading = 'Contact Us',
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Got questions or ideas? Reach out anytime! We're here and excited to connect.",
  submitButtonText = 'Send',
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id="contact-us">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={formSchema}>
              {(props) => {
                const { setFieldValue, values, errors, isValid, isSubmitting } = props;
                return (
                  <CustomForm>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Your Email Address"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      value={values.email}
                    />
                    {errors.email && <ErrorText>{errors.email}</ErrorText>}
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Full Name"
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      value={values.name}
                    />
                    {errors.name && <ErrorText>{errors.name}</ErrorText>}
                    <Select
                      id="industry"
                      name="industry"
                      placeholder="Industry"
                      style={{ color: '#A0AEBF' }}
                      onChange={(e) => setFieldValue('industry', e.target.value)}
                      value={values.industry}>
                      <option value="" disabled>
                        Select Industry
                      </option>
                      {industryList.map((industry) => (
                        <option value={industry}>{industry}</option>
                      ))}
                    </Select>
                    {errors.industry && <ErrorText>{errors.industry}</ErrorText>}
                    <Textarea
                      id="message"
                      name="message"
                      placeholder="Your Message Here"
                      onChange={(e) => setFieldValue('message', e.target.value)}
                      value={values.message}
                    />
                    {errors.message && <ErrorText>{errors.message}</ErrorText>}
                    <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
                      {submitButtonText}
                    </SubmitButton>
                  </CustomForm>
                );
              }}
            </Formik>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
