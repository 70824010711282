import { object, string } from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';

export const initialValues = {
  name: '',
  email: '',
  message: '',
  region: '',
  industry: '',
  opt_in: true
};

/*
 * formSchema serves both as the initial state to form as well
 * as set of validation rules to apply on form fields
 * */
export const formSchema = object().shape({
  name: string().required('Name is Required'),
  email: string().required('Email is Required').email('Invalid Email Address'),
  message: string().required('Message is Required'),
  industry: string().required('Industry is Required')
});

const toastStyles = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark'
};

export const handleSubmit = async (values, { resetForm }) => {
  axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/contact-submissions/`, values, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((resp) => {
      resetForm();
      toast.success(resp.data.message, toastStyles);
    })
    .catch((err) => {
      toast.error(err.message ?? err.data.message, toastStyles);
    });
};

export const initialNewsletterValues = {
  email: ''
};

/*
 * formSchema serves both as the initial state to form as well
 * as set of validation rules to apply on form fields
 * */
export const newsletterFormSchema = object().shape({
  email: string().required('Email is Required').email('Invalid Email Address')
});

export const handleNewsletterSubmit = async (values, { resetForm }) => {
  axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/newsletter-subscriptions/`, values, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((resp) => {
      resetForm();
      toast.success(resp.data.message, toastStyles);
    })
    .catch((err) => {
      toast.error(err.message ?? err.data.message, toastStyles);
    });
};

export const industryList = [
  'Agriculture',
  'Automotive',
  'Banking',
  'Construction',
  'Education',
  'Energy',
  'Entertainment',
  'Fashion',
  'Finance',
  'Food and Beverage',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Manufacturing',
  'Media',
  'Pharmaceutical',
  'Real Estate',
  'Retail',
  'Technology',
  'Telecommunications',
  'Transportation',
  'Travel',
  'Utilities',
  'Other'
];
