import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks
} from '../headers/light.js';
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import ReactModalAdapter from 'helpers/ReactModalAdapter.js';
import HireUs from 'components/modals/HireUs.js';

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto sm:m-16 md:m-40 max-w-screen-xl inset-0 absolute flex-col justify-center items-center rounded-lg bg-primary-500 outline-none shadow-2xl`}
  }
  .content {
    ${tw`w-full lg:p-20`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 z-50 mt-8 mr-8 text-white`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-green-100 hover:border-green-300 hover:text-green-300`}
  }
  ${DesktopNavLinks} ${PrimaryLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url('https://i.postimg.cc/hjp6SBGk/blue-futuristic-networking-technology-53876-100679.avif'); /* Add your background image URL here */
  &:before {
    content: '';
    ${tw`absolute inset-0 bg-black opacity-5`}
  }
  &:after {
    content: '';
    ${tw`absolute inset-0 bg-cover bg-center filter blur`}
    background-image: url('https://i.postimg.cc/hjp6SBGk/blue-futuristic-networking-technology-53876-100679.avif'); /* Add your background image URL here */
  }
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-4xl xl:text-4xl font-black text-green-100 lg:ml-6 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-gray-200 transform -skew-x-12 -z-10`}
  }
`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base lg:ml-6 sm:mt-16 sm:px-8 sm:py-4 bg-primary-500 text-gray-100 font-bold rounded shadow transition duration-300 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const scrollToSection = (id) => {
    const testimonialsSection = document.getElementById(id);
    if (testimonialsSection) {
      testimonialsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => scrollToSection('about')}>About</NavLink>
      <NavLink onClick={() => scrollToSection('services')}>Services</NavLink>
      <NavLink onClick={() => scrollToSection('contact-us')}>Contact Us</NavLink>
      <PrimaryLink onClick={() => scrollToSection('contact-us')}>Hire Us</PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      {/* <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}>
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <HireUs />
      </StyledModal> */}
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>Your Ultimate Tech Companion</span>
              <br />
              <SlantedBackground>for Innovative Digital Solutions</SlantedBackground>
            </Heading>
            <PrimaryAction onClick={() => scrollToSection('testimonials')}>
              Read Client Stories
            </PrimaryAction>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
