import React, { useState } from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import { SectionHeading } from 'components/misc/Headings';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons';
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';
import LearnNConnect from 'images/learn-connect.png';
import DopeWarz from 'images/dopewarz.png';
import Puzzicle from 'images/puzzicle.png';
import BookCoin from 'images/bookcoin.png';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`flex justify-center mb-1`}
  }
`;

const Card = styled.div(() => [
  `cursor: pointer;`,
  tw`h-full flex flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none hover:shadow-md`
]);

const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}"); cursor: pointer;`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const cards = [
    {
      imageSrc: LearnNConnect,
      title: 'Learn and Connect',
      description:
        'This pertains to the school administration system, where teachers oversee events, manage student absences, and communicate with students via chat.',
      url: 'https://my-school-fe.fly.dev'
    },
    {
      imageSrc: DopeWarz,
      title: 'Dope Warz',
      description:
        'This platform primarily facilitates watch parties where students can collectively watch content and engage in quizzes integrated within the viewing experience.',
      url: 'https://dopewarz.io/blackmarket'
    },
    {
      imageSrc: Puzzicle,
      title: 'Puzzicle',
      description:
        'This is a decentralized marketplace built on blockchain technology, where users can create and purchase books as NFTs (Non-Fungible Tokens).',
      url: ' https://puzzicle.org/'
    },
    {
      imageSrc: BookCoin,
      title: 'Book Coin',
      description:
        'A peer-to-peer (P2P) marketplace that connects cryptocurrency buyers and sellers.',
      url: 'https://www.bookcoin.com/'
    }
  ];

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Projects</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card
              key={index}
              onClick={() => {
                window.open(card.url, '_blank');
              }}
            >
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>
                <Description>{card.description}</Description>
              </TextInfo>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
